module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NTE AS","short_name":"NTE","start_url":"/","background_color":"#f7f0eb","theme_color":"#0079AD","display":"standalone","icon":"src/images/nte-favicon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"448c46c4d8e150ff46894147782fc4bd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Hjem","exclude":["**/dev-404-page/**","**/404/**","**/404.html"],"excludeOptions":{"separator":"."},"crumbLabelUpdates":[{"pathname":"/blogg","crumbLabel":"Blogg"},{"pathname":"/strom","crumbLabel":"Strøm"},{"pathname":"/strom-og-energitjenester","crumbLabel":"Strøm og energitjenester"},{"pathname":"/sok","crumbLabel":"Søkeresultater"},{"pathname":"/elektrikertjenester","crumbLabel":"Elektrikertjenester"},{"pathname":"/agrol","crumbLabel":"Agrol"},{"pathname":"/kundeservice","crumbLabel":"Kundeservice"},{"pathname":"/produkter","crumbLabel":"Produkter"},{"pathname":"/om-nte","crumbLabel":"Om NTE"},{"pathname":"/om-nte/barekraft-i-nte","crumbLabel":"Bærekraft i NTE"},{"pathname":"/naermiljoet-mitt","crumbLabel":"Nærmiljøet Mitt"},{"pathname":"/bedrift/strom-og-energitjenester/stromavtaler","crumbLabel":"Strømavtaler"},{"pathname":"/strom/[...]","crumbLabel":"Strømbestilling"},{"pathname":"/tv-og-internett","crumbLabel":"TV og Internett"}],"trailingSlashes":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

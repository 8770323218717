import React from 'react';
import styled, { css } from 'styled-components';

import { showContent } from 'libs/animations';
import Elproffen from './elproffen.inline.svg';
import ScreenReaderText from 'components/screen-reader-text';

const Wrap = styled.div`
	z-index: 5;
	display: flex;
	align-items: center;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	${p =>
		p.$animate &&
		css`
			animation-name: ${showContent};
			animation-delay: 1.25s;
			animation-duration: 2s;
			animation-fill-mode: both;
		`}

	${p =>
		p.centered &&
		css`
			justify-content: center;
		`};

	svg {
		margin-top: 0;
		margin-left: ${p => p.theme.spacing.desktop.xxsmall};
	}

	span {
		font-weight: 600;
		font-size: 17px;
		line-height: 29px;
		text-transform: uppercase;
		color: ${p =>
			p.color === 'black' ? p.theme.colors.grey900 : 'white'} !important;
	}
`;

export default function ElproffenLogo({
	animate = false,
	color = 'black',
	...props
}) {
	return (
		<Wrap $animate={animate} color={color} {...props}>
			<ScreenReaderText>
				Vi er medlem av elektrikerkjeden Elproffen
			</ScreenReaderText>
			<span>Vi er medlem av</span>
			<Elproffen
				aria-label="Vi er medlem av elektrikerkjeden Elproffen"
				width="137"
				height="26"
			/>
		</Wrap>
	);
}

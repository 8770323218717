import React from 'react';
import { StyleSheetManager } from 'styled-components';

import { shouldForwardProp } from './gatsby-shared';
export { wrapPageElement } from './gatsby-shared';

window.dataLayer = window.dataLayer || [];

export const wrapRootElement = ({ element }) => {
	return (
		<StyleSheetManager shouldForwardProp={shouldForwardProp}>
			{element}
		</StyleSheetManager>
	);
};

export const onRouteUpdate = ({ prevLocation }) => {
	if (typeof window.analytics !== 'undefined' && prevLocation) {
		// On every route change fire this code!!
		setTimeout(() => {
			window.analytics.page(document?.title);
		}, 50);
	}
};
